
const system = {
    namespaced: true,
    state: {
        contentHeight: 100,
        userInfo:null
    },
    actions: {},
    mutations: {
        SET_CONTENT_HEIGHT: (state, height) => {
            state.contentHeight = height
        },
        SET_USERINFO:(state,userInfo)=>{
            state.userInfo = userInfo
        }
    }
}
export default system
