/*
 * @Descripttion:
 * @version:
 * @Author: chenyu
 * @Date: 2024-08-05 10:55:31
 * @LastEditors: chenyu
 * @LastEditTime: 2024-09-12 10:31:13
 */
/*
 * @Descripttion:
 * @version:
 * @Author: chenyu
 * @Date: 2024-08-05 10:55:31
 * @LastEditors: chenyu
 * @LastEditTime: 2024-09-11 13:44:17
 */
/*
 * @Descripttion:
 * @version:
 * @Author: chenyu
 * @Date: 2024-08-05 10:55:31
 * @LastEditors: chenyu
 * @LastEditTime: 2024-09-11 09:19:59
 */

import Vue from "vue";
import VueRouter from "vue-router";
import children from "@/views/managementSystem/router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/index.vue"),
  },
  {
    path: "/search",
    name: "search",
    component: () => import("@/views/search.vue"),
  },
  {
    path: "/diyPage",
    name: "diyPage",
    component: () => import("@/views/diyPage.vue"),
  },
  {
    path: "/storeDetails",
    name: "storeDetails",
    component: () => import("@/views/storeDetails"),
  },
  {
    path: "/system",
    component: () => import("@/views/managementSystem/layout/index.vue"),
    children,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/register.vue"),
  },
  {
    path: "/orderConfirm",
    name: "orderConfirm",
    component: () => import("@/views/managementSystem/pages/orderConfirm.vue"),
  },
  {
    path: "/afterOrderDetails/:id",
    name: "afterOrderDetails",
    component: () =>
      import(
        "@/views/managementSystem/pages/purchasOrderManage/afterOrderDetails.vue"
      ),
  },
  {
    path: "/orderDetail/:id",
    name: "orderDetail",
    component: () => import("@/views/managementSystem/pages/purchasOrderManage/orderDetail.vue"),
  },
  {
    path: "/paySuccess",
    name: "paySuccess",
    component: () => import("@/views/managementSystem/pages/paySuccess.vue"),
  },
  {
    path: "/agreement",
    name: "agreement",
    component: () => import("@/views/agreement.vue"),
  },
  {
    path: "/openPlatform",
    name: "openPlatform",
    component: () => import("@/views/openPlatform/layout/index.vue"),
    children: [
      {
        path: "/",
        redirect: "/openPlatform/controlBoard/home",
      },
      {
        path: "document",
        name: "document",
        component: () =>
          import("@/views/openPlatform/layout/documentLayout.vue"),
      },
      {
        path: "controlBoard",
        name: "controlBoard",
        component: () =>
          import("@/views/openPlatform/layout/controlBoardLayout.vue"),
        children: [
          {
            path: '/',
            redirect: '/openPlatform/controlBoard/home'
          },
          {
            path: 'home',
            name: "home",
            component: () =>
              import("@/views/openPlatform/page/controlBoard/home.vue"),
          },
          {
            path: "application",
            name: "application",
            component: () => import("@/views/openPlatform/page/controlBoard/application.vue"),
          },
          {
            path: 'applicationEdit',
            name: "applicationEdit",
            component: () => import("@/views/openPlatform/page/controlBoard/applicationEdit.vue"),
          }
        ]
      },
    ]
  }
];

const router = new VueRouter({
  routes,
});

export default router;
