<template>
  <div class="system-basic-container" v-loading="loading">
    <div class="system-item">
      <div :class="['system-item-content', getCurrentClass()]" ref="systemRef">
        <el-scrollbar style="height: 100%" class="not-scroll-x">
          <slot></slot>
        </el-scrollbar>
      </div>
      <div class="system-basic-footer" v-if="total > pageSize || showFooter">
        <div class="page-box" v-if="total > pageSize">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentNum"
            :page-size="currentSize"
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="op-box">
          <div class="op-left"><slot name="bottomLeft"></slot></div>
          <div class="op-rigth"><slot name="bottomRight"></slot></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: [Number, String],
      default: 35,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    pageNum: {
      type: [Number, String],
      default: 1,
    },
    pageSize: {
      type: [Number, String],
      default: 20,
    },
    currentPage: {
      type: Array,
      default: () => [20, 40, 60, 80, 100],
    },
    loading:{
      type: Boolean,
      default: false,
    }
  },
  watch:{
    total(){
      this.getContentHeight()
    },
    showFooter(){
      this.getContentHeight()
    }
  },
  computed: {
    currentNum: {
      get() {
        return this.pageNum;
      },
      set(val) {
        this.$emit("update:pageNum", val);
      },
    },
    currentSize: {
      get() {
        return this.pageSize;
      },
      set(val) {
        this.$emit("update:pageSize", val);
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    this.getContentHeight();
    window.addEventListener("resize", this.getContentHeight);
  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.currentSize=val;
      this.$emit("getList")
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentNum=val;
      this.$emit("getList")
    },
    getContentHeight() {
      this.$nextTick(() => {
        let systemDom = this.$refs.systemRef;
        if (systemDom && systemDom.offsetHeight) {
          this.$store.commit(
            "system/SET_CONTENT_HEIGHT",
            systemDom.offsetHeight
          );
        }
      });
    },
    getCurrentClass() {
      if (this.total > this.pageSize && this.showFooter) {
        return "is-show-page-footer";
      } else if (this.total > this.pageSize && !this.showFooter) {
        return "is-show-page-not-footer";
      } else if (this.total < this.pageSize && this.showFooter) {
        return "is-show-footer-not-page";
      } else if (this.total < this.pageSize && !this.showFooter) {
        return "is-not-page-footer";
      } else {
        return "is-show-footer-not-page";
      }
    },
  },
};
</script>

<style lang='less' scoped>
.system-basic-container {
  padding: @padding;
}
.system-item {
  border-radius: @border-r;
  background: #fff;
  height: @views-height;
  overflow: hidden;
}
.system-basic-footer {
  box-shadow: 0px -1px 3px 1px rgba(0, 0, 0, 0.06);
}
.page-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: @pagination-height;
  padding-right: 24px;
}
.op-box {
  height: @bottom-height;
  padding: 0 75px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.04);
}
.is-show-page-not-footer {
  height: calc(@views-height - @pagination-height);
}
.is-show-footer-not-page {
  height: calc(@views-height - @bottom-height);
}
.is-not-page-footer {
  height: @views-height;
}
.is-show-page-footer {
  height: calc(@views-height - @bottom-height - @pagination-height);
}
</style>