<!--
 * @Descripttion: 
 * @version: 
 * @Author: chenyu
 * @Date: 2023-09-06 15:25:06
 * @LastEditors: chenyu
 * @LastEditTime: 2024-09-25 10:18:40
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
}
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100vh;
  background-color: white;
}

// ::-webkit-scrollbar {
//   width: 0 !important;
// }
// ::-webkit-scrollbar {
//   width: 0 !important;
//   height: 0;
// }
.OneWay {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.MultiLine_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.smallHand {
  cursor: pointer;
}
</style>
