/*
 * @Author: 莫真琦 mrmo169fe@163.com
 * @Date: 2023-09-11 18:01:44
 * @LastEditors: chenyu
 * @LastEditTime: 2024-09-25 10:21:17
 * @FilePath: \goods-market\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
import '@/views/managementSystem/assets/styles/index.less';
import '@/views/managementSystem/assets/styles/variables.less';
import './assets/styles/element-variables.scss';
import "./views/managementSystem/assets/icon/iconfont.css";
import "./views/managementSystem/assets/icon/iconfont";
import addCartDialog from '@/views/components/addCartDialog'
import systemBasicContainer from "@/views/managementSystem/components/basic-container/index.vue";

Vue.config.productionTip = false
//引入全局指令消除element-ui单选组件el-radio-group 的报错问题
Vue.directive('removeHidden', {
  bind(el) {
    // 查找所有匹配的元素
    let ariaEl = el.querySelectorAll('.el-radio__original[aria-hidden]');
    ariaEl.forEach((item) => {
      // 移除 aria-hidden 属性
      item.removeAttribute('aria-hidden');
    });
  }
});
Vue.use(ElementUI);
Vue.use(Avue);
Vue.component('addCartDialog',addCartDialog)
Vue.component("systemBasicContainer", systemBasicContainer)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
